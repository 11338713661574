import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    newsslider() {
        const swiper = new Swiper(
            '.topics-slider[data-plugin="topicsSlider"]',
            {
                slidesPerView: 'auto',
                // slidesPerView: 1,
                loop: true,
                spaceBetween: 24,
                grabCursor: true,
                pagination: {
                    el: '.topics-slider .swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.topics-slider .swiper-button-next',
                    prevEl: '.topics-slider .swiper-button-prev',
                },
                breakpoints: {
                    576: {
                        // slidesPerView: 2,
                    },
                    768: {
                        // slidesPerView: 3,
                    },
                    1200: {
                        // slidesPerView: 4,
                    },
                },
            }
        );
    }
    voiceslider() {
        const swiper = new Swiper('.voice-slider[data-plugin="voiceSlider"]', {
            slidesPerView: 1,
            spaceBetween: 21,
            loop: true,
            centeredSlides: true,
            loopAdditionalSlides: 1,
            speed: 1000,
            // autoplay: {
            //     delay: 4000,
            //     disableOnInteraction: false,
            // },
            grabCursor: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.voice-slider .swiper-button-next',
                prevEl: '.voice-slider .swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            },
        });
    }
}

const domReady = () => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.newsslider();
        blocks.voiceslider();
    });
};

export default function HOME() {
    d.addEventListener('DOMContentLoaded', domReady());
}
