import LazyDisp from './lazydisp';
// import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";

const [d] = [document];

export default class COMMON {
  constructor(opt) {
    // settings
    this.opt = Object.assign({
      lazydisp: '[data-lazydisp]',
    }, opt);
	}
  init()
  {
    // イベント一覧
    d.addEventListener('Lazydisp.beforeInit', () => false);

		// イベント発火
    d.dispatchEvent(new CustomEvent('common.beforeInit', { detail: this }));
    this.LazyDisp = new LazyDisp(this.opt.lazydisp);
    // console.log(this.LazyDisp);
	}

  domReady() {
    // console.log(`test`);
  }
}