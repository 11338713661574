const [w, d, l] = [window, document, location];

export default class Utility {
    constructor() {
        this.init();
    }
    init() {
        //ハンバーガーメニュー
        const $drowerBtn = $('.drawer_tab,.drawer ._anchor-link');
        const $drowerMenu = $('.drawer_wrap');
        $drowerBtn.on('click', function (e) {
            $drowerBtn.toggleClass('active');
            $drowerMenu.toggleClass('open');
            // return false;
        });

        window.setTimeout(function () {
            $drowerMenu.addClass('js-load');
        }, 1000);

        //トグル
        $('.tabsList__contents').hide();
        $('.tabsList__btn').on('click', function () {
            $(this).next('.tabsList__contents').slideToggle();
        });

        //アコーディオン
        const $qaQuestion = $('._question');
        const $qaAnswer = $('._answer');
        $qaAnswer.hide();
        $qaQuestion.on('click', function () {
            $(this).toggleClass('is-active');
            $(this).next().slideToggle();
        });

        const button = document.querySelector('.l-footer__totop');

        //クリックイベント
        button.addEventListener('click', () => {
            //ページ上部へスムーススクロール
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
        });

        //スクロールイベント
        window.addEventListener('scroll', () => {
            //スクロール量を判定して要素にクラスを付与
            if (window.scrollY > 100) {
                button.classList.add('js-scroll');
            } else {
                button.classList.remove('js-scroll');
            }
        });

        // current page highlight
        const $cr_navi = $('.gnav li a');
        $cr_navi.each(function () {
            const this_href = $(this).attr('href');
            const abs_path = $(this).attr('href', this_href).get(0).href;
            if (abs_path === l.href) {
                $(this).addClass('is-current');
            }
            //header内のみ親階層もcurrentを付ける
            if ($(this).parents('header').length) {
                if (l.href.indexOf(abs_path) != -1) {
                    $(this).addClass('is-current');
                }
            }
        });

        //外部リンク設定
        const newtabConfig = {
            //下階層で外部リンクに設定したい階層を設定してください。
            // 例） internal:['/directory/', '/fugafuga/'],
            internal: [],
            //外部のURLだが別タブにしたくないURLを設定してください。
            // 例） ignore:['https://example.com', 'http://hoge.co.jp'],
            ignore: [],
        };
        const siteDomain = document.domain;
        const siteDomainRegex = new RegExp(`^https?:\/\/(www\.)?${siteDomain}`);
        const internalRegex = () => {
            if (
                newtabConfig.internal === null ||
                newtabConfig.internal.length === 0
            ) {
                return false;
            }
            return new RegExp(newtabConfig.internal.join('|'));
        };
        const ignoreRegex = () => {
            if (
                newtabConfig.ignore === null ||
                newtabConfig.ignore.length === 0
            ) {
                return false;
            }
            return new RegExp(newtabConfig.ignore.join('|'));
        };
        const isHrefNewtab = (href) => {
            if (internalRegex()) {
                return href.match(internalRegex()) ? true : false;
            } else {
                return false;
            }
        };
        const isHrefExternal = (href) => {
            const isFullPath = /^https?/.test(href);
            if (ignoreRegex()) {
                if (href.match(ignoreRegex())) {
                    return false;
                }
            }
            const isExternalURL =
                href.match(siteDomainRegex) === null && isFullPath;
            return isExternalURL ? true : false;
        };
        $('a').each(function () {
            const hrefAttr = $(this).attr('href');
            if (isHrefExternal(hrefAttr) || isHrefNewtab(hrefAttr)) {
                $(this)
                    .addClass('externalLink')
                    .attr('target', '_blank')
                    .attr('rel', 'noopener');
            }
        });

        //PDF target brank
        $('a[href$=".pdf"],a[href$=".asx"]').each(function () {
            $(this)
                .addClass('pdfLink')
                .attr('target', '_blank')
                .attr('rel', 'noopener');
        });

        //hamburger
        $(document).on('click', 'header .hamburger,header nav a', function () {
            $('body').toggleClass('modal_mode');
            $('header .hamburger').toggleClass('is-active');
            $('nav').toggleClass('menu_open');
        });

        // 電話番号設定
        $('[data-tellink]').each(function () {
            const telLink = $(this).attr('data-tellink');
            $(this).wrapInner('<a href="tel:' + telLink + '"></a>');
        });
    }

    //URLによる読み込み分岐
    loader(loaderSwitch) {
        let path = l.pathname;
        let request = l.pathname + l.search;
        loaderSwitch(request, path);
    }
}
