import COMMON from '../class/common';
// import LazyDisp from "../class/lazydisp";

const [d] = [document];

export default class CUSTOMIZE extends COMMON {
  constructor(opt) {
    opt = Object.assign({

    },opt);

    // 上位クラスのコンストラクタ呼び出し
    super(opt);

    this.customInit();
  }
  customInit()
  {
    $(() => {
      // new LazyDisp();
      // console.log("test");
    });

  }
}